import {autoinject} from "aurelia-framework";
import {Router} from "aurelia-router";
import {I18N} from "aurelia-i18n";
import {DialogService} from "aurelia-dialog";
import {SmileService} from "./SmileService";
import {LocationService} from "./LocationService";
import {fhirEnums} from "../classes/fhir-enums";
import {FhirService} from "./FhirService";
import {HttpClient} from "aurelia-http-client";
import {NitTools} from "../classes/NursitTools";
import {DialogMessages} from "./DialogMessages";
import * as environment from "../../../config/environment.json";
import {RuntimeInfo} from "../classes/RuntimeInfo";
import {ISmileUser} from "../../views/admin/interfaces/ISmileUser";
import {PumpService} from "./PumpService";
import {ConfigService} from "./ConfigService";
import {IdLogikService} from "./IdLogikService";
import { PermissionService } from "./PermissionService";

@autoinject
export class UserService {
    private router: Router;
    private smileService: SmileService;
    private locationService: LocationService;
    private i18n: I18N;
    private fhirService: FhirService;
    private idLogikService: IdLogikService;
    private permissionService: PermissionService;
    private __username: string;
    public static Practitioner: any;
    public static Role: any;
    public static Qualifications: string[];

    public static GetPractitionerQualifications(practitioner : any) : string[] {
        const practitionerQualifications = [];
        for (const i of practitioner?.qualification?.filter(o => o.code?.coding)) {
            for (const ii of i.code.coding) {
                practitionerQualifications.push(ii.code);
            }
        }

        return practitionerQualifications;
    }

    public static PractitionerHasQualification(practitioner: any, qualifications: string[]): boolean {
        let result = false;
        if (practitioner && practitioner?.qualification) {
            const practitionerQualifications = this.GetPractitionerQualifications(practitioner);

            for (let i = 0; i < qualifications.length; i++) {
                for (let ii = 0; ii < practitionerQualifications.length; ii++) {
                    if (practitionerQualifications[ii].toUpperCase() === qualifications[i]) {
                        result = true;
                        break;
                    }
                }

                if (result) {
                    break;
                }
            }
        }

        return result;
    }
    public static UserHasQualification(qualifications: string[]): boolean {
        return this.PractitionerHasQualification(UserService.Practitioner, qualifications);
    }

    private get _username(): string {
        return this.__username;
    }

    private set _username(value: string) {
        this.__username = value;
        UserService.UserName = value;
        RuntimeInfo.Employee.userId = value;
    }

    private static __UserRole: string = undefined; // static backing field for USERROLE Property
    public static get UserRole(): string {
        return this.GetUserRole(UserService.Practitioner);
    }

    public static GetUserRole(practitioner): string {
        if (NitTools.IsArray(practitioner?.identifier)) {
            const roleIdent = practitioner.identifier.find(o => o.system?.indexOf('/smile-user-role') > -1 && o.value);
            return roleIdent?.value;
        }

        return undefined;
    }

    private _noLogin = false;
    private _isDataLoaded = false;
    private __firstName: string;
    private get _firstName(): string {
        return this.__firstName;
    }

    private set _firstName(value: string) {
        this.__firstName = value;
        UserService.UserFirstName = value;
        RuntimeInfo.Employee.givenName = value;
    }

    private __lastName: string;
    private get _lastName(): string {
        return this.__lastName;
    }

    private set _lastName(value: string) {
        this.__lastName = value;
        UserService.UserLastName = value;
        RuntimeInfo.Employee.familyName = value;
    }

    private dialogService: DialogService;
    private dialogMessages: DialogMessages;

    public get practitioner(): any {
        return UserService.Practitioner;
    }

    public set practitioner(value: any) {
        UserService.Qualifications = [];
        UserService.Practitioner = value;
        UserService.__UserRole = undefined;

        if (value?.qualification) {
            for (const i of value.qualification.filter(o => o.code?.coding)) {
                for (const ii of i.code.coding) {
                    UserService.Qualifications.push(ii.code);
                }
            }
        }

        this.ensureMissingQualifications();
    }

    /***
     * Ensures that the rising Qualifications are recognized correctly. iE that a PFA includes all SAB+HH+PA+24H permissions by adding them to the Qualifications if not set
     * @private
     */
    private ensureMissingQualifications() {
        const ensureQualification = (qualificationCode: string[]) => {
            for (let code of qualificationCode) {
                code = code.toUpperCase();
                if (UserService.Qualifications.indexOf(code) === -1)
                    UserService.Qualifications.push(code);
            }
        };

        if (!UserService.Qualifications) UserService.Qualifications = [];
        if (UserService.Qualifications.indexOf('SSL') > -1) {
            ensureQualification(['PPK', 'SAB', 'HH', 'H24', '24H', 'PA', 'PFA', 'DGKP']);
        } else if (UserService.Qualifications.indexOf('PPK') > -1) {
            ensureQualification(['SAB', 'HH', 'H24', '24H', 'PA', 'PFA', 'DGKP']);
        } else if (UserService.Qualifications.indexOf('DGKP') > -1) {
            ensureQualification(['SAB', 'HH', 'H24', '24H', 'PA', 'PFA']);
        } else if (UserService.Qualifications.indexOf('PFA') > -1) {
            ensureQualification(['SAB', 'HH', 'H24', '24H', 'PA']);
        } else if (UserService.Qualifications.indexOf('24H') > -1 || UserService.Qualifications.indexOf('H24') > -1) {
            ensureQualification(['SAB', 'HH', 'H24', '24H', 'PA']);
        } else if (UserService.Qualifications.indexOf('PA') > -1) {
            ensureQualification(['SAB', 'HH']);
        } else if (UserService.Qualifications.indexOf('HH') > -1) {
            ensureQualification(['SAB']);
        } else if (UserService.Qualifications.indexOf('HH') > -1) {
            ensureQualification(['SAB']);
        }
    }

    public get qualifications(): string[] {
        return UserService.Qualifications;
    }

    public get practitionerRole(): any {
        return UserService.Role;
    }

    public set practitionerRole(value: any) {
        UserService.Role = value;
    }

    public static Wards: any[] = [];

    get wards() {
        return UserService.Wards;
    }

    set wards(value) {
        UserService.Wards = value;
    }

    defaultWard;
    private static _userCache: any[];

    public static GetNameObject(practitioner, ignoreExistingText = false): any {
        let hn: any = undefined;
        if (!practitioner) return undefined;

        if (practitioner?.name) {
            hn = practitioner.name.find(o => o.use === 'official');
            if (!hn) hn = practitioner.name.find(o => o.use === 'usual');
            if (!hn) hn = practitioner.name[0];
        }

        if (hn && (!hn.text || ignoreExistingText)) {
            let s = hn.family + (hn.given && hn.given?.length > 0 ? ', ' + hn.given?.join(' ') : '');
            if (hn.prefix && hn.prefix.length > 0) {
                s = hn.prefix.join(' ') + ' ' + s;
            }

            if (hn.suffix && hn.suffix.length > 0) {
                s += ' ' + hn.suffix.join(' ');
            }

            hn.text = s.trim();
        }

        return hn;
    }

    public static async GetPractitioner(practitionerId: string, fhirService: FhirService): Promise<any> {
        if (!UserService._userCache) UserService._userCache = [];
        if (practitionerId.indexOf('/') > -1) practitionerId = practitionerId.split('/')[1];
        return new Promise<any>(async (resolve) => {

            let result: any = UserService._userCache.find(o => o.id === practitionerId);
            try {
                if (!result) {
                    result = <any>await fhirService.get(`Practitioner/${practitionerId}`);

                    if (result) {
                        const hn = this.GetNameObject(result);

                        if (hn) {
                            result.text = {
                                status: 'generated',
                                div: `<span xmlns="http://www.w3.org/1999/xhtml">${hn.text}</span>`
                            };
                        } else {
                            result.text = {
                                status: 'generated',
                                div: `<span xmlns="http://www.w3.org/1999/xhtml">ohne Namen</span>`
                            };
                        }

                        UserService._userCache.push(result);
                    }
                }
            } catch (error) {
                console.warn(error.message || error);
                result = undefined;
            }

            resolve(result);
            return;
        });
    }

    checkEmbedded(): boolean {
        // "embedded=1" AND ("/visit/" or "/visiteExtended/") in the url is no login reason too
        if (/embedded=1/gi.test(window.location.href) && (
            (/\/visit.*\//gi.test(window.location.href) || /\/ward.*\//gi.test(window.location.href))
        )) return true;
    }

    constructor(router: Router, i18n: I18N, dialogService: DialogService, dialogMessages: DialogMessages, idLogikService: IdLogikService, permissionService: PermissionService) {
        this.router = router;
        this.i18n = i18n;
        this.fhirService = new FhirService();
        this.smileService = new SmileService();

        this.dialogService = dialogService;
        this.locationService = new LocationService();
        this.dialogMessages = dialogMessages;
        this.idLogikService = idLogikService;
        this.permissionService = permissionService;

        if (this.urlHasSessionId() || (!UserService.Login.useFhirLogin && !UserService.Login.usePrincipa) || this.checkEmbedded()) {
            this._noLogin = true;
            this._username = 'defaultUser';
        }

        if (ConfigService.Debug) {
            window["UserService"] = UserService;
            window["userService"] = this;
        }
    }

    public static UserName: string;
    public static UserFirstName: string;
    public static UserLastName: string;
    public static IsLoggedIn: boolean = false;

    public static async EnsureUserExistsLocal(): Promise<any> {
        if (FhirService.IsOffline || !FhirService.OfflineClientSettings || !FhirService.OfflineClientSettings.local.url) return Promise.reject("Not all neede properties given");
        const ssLocal = new SmileService(FhirService.OfflineClientSettings.local.admin.endpoint, FhirService.OfflineClientSettings.local.admin.user);
        const loadedUser = await ssLocal.getUser(UserService.UserName);
        if (loadedUser) return Promise.resolve(loadedUser);

        // local user has not been found
        const jsUser = {
            nodeId: "Master",
            moduleId: "local_security",
            username: UserService.UserName,
            familyName: UserService.UserLastName,
            givenName: UserService.UserFirstName,
            accountLocked: false,
            systemUser: false,
            authorities: [
                {permission: "ROLE_FHIR_CLIENT_SUPERUSER"},
                {permission: "ROLE_FHIR_CLIENT"},
                {permission: "FHIR_CAPABILITIES"},
                {permission: "FHIR_ALL_WRITE"},
                {permission: "ACCESS_ADMIN_JSON"},
                {permission: "FHIR_OP_ENCOUNTER_EVERYTHING"},
                {permission: "FHIR_TRANSACTION"},
                {permission: "FHIR_ALL_READ"},
                {permission: "FHIR_META_OPERATIONS_SUPERUSER"},
                {permission: "FHIR_ALL_DELETE"},
                {permission: "FHIR_BATCH"},
                {permission: "CHANGE_OWN_PASSWORD"},
                {permission: "CHANGE_OWN_DEFAULT_LAUNCH_CONTEXTS"},
                {permission: "VIEW_USERS"},
                {permission: "FHIR_OP_PATIENT_EVERYTHING"}
            ],
            password: atob(FhirService.Hash).split(':')[1],
            accountDisabled: false,
            external: false,
            serviceAccount: false,
            twoFactorAuthStatus: "NO_KEY_DEFINED"
        };

        try {
            let result: any = await ssLocal.createUser(jsUser,
                FhirService.OfflineClientSettings.local.admin.endpoint,
                FhirService.OfflineClientSettings.local.admin.user,
                FhirService.OfflineClientSettings.local.admin.userNodeId,
                FhirService.OfflineClientSettings.local.admin.userModuleId);


            return Promise.resolve(result);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    get isLoggedIn() {
        return UserService.IsLoggedIn;
    }

    set isLoggedIn(value: boolean) {
        UserService.IsLoggedIn = value;
    }

    set username(value: string) {
        this._username = value;
    }

    get username() {
        let us = this._username || sessionStorage.getItem(this.impersonationKey);
        return us;
    }

    get noLogin() {
        return this._noLogin;
    }

    get firstName() {
        return this._firstName;
    }

    get lastName() {
        return this._lastName;
    }

    get fullNameOrUsername() {
        const fullName = [];

        if (this._lastName) {
            fullName.push(this._lastName);
        }

        if (this._firstName) {
            fullName.push(this._firstName);
        }

        if (fullName.length > 0) {
            return fullName.join(', ');
        } else {
            return this._username;
        }
    }

    get impersonationKey(): string {
        return environment.sessionName + "_impersonation";
    }

    async autoGenerateUser(userId: string, familyName: string, givenName: string): Promise<any> {
        let result = {isNew: false, user: undefined, userId: undefined, pid: undefined};
        let service = new SmileService();
        let userResult = await service.getRemoteUser(userId); //  getUser(userId, FhirService.RemoteSetup.fhirAdmin, UserService.AdminUsers.local);
        let pass = RuntimeInfo.Features.autoUserCreation?.enabled === true && RuntimeInfo.Features.autoUserCreation.defaultPassword ? RuntimeInfo.Features.autoUserCreation.defaultPassword : 'Passwort123!';
        let params = NitTools.GetUrlParams();
        if (params.pass) pass = String(params.pass);
        let unlock = RuntimeInfo.Features.autoUserCreation && RuntimeInfo.Features.autoUserCreation.enabled && RuntimeInfo.Features.autoUserCreation.unlockAccount === true;
        if (params.unlock) unlock = params.unlock;

        if (typeof userResult === "undefined" || (!userResult["users"] || (<any[]>userResult["users"]).length === 0) && (!userResult["username"])) {
            result.isNew = true;
            let userData: ISmileUser = {
                "username": userId.toUpperCase(),
                "accountDisabled": false,
                "accountLocked": !unlock,
                "givenName": givenName,
                "familyName": familyName,
                "systemUser": false,
                "authorities": [
                    {permission: "ROLE_FHIR_CLIENT"},
                    {permission: "CHANGE_OWN_PASSWORD"},
                    {permission: "CHANGE_OWN_DEFAULT_LAUNCH_CONTEXTS"},
                    {permission: "VIEW_USERS"},
                    {permission: "FHIR_CAPABILITIES"},
                    {permission: "FHIR_ALL_WRITE"},
                    {permission: "FHIR_ALL_DELETE"},
                    {permission: "FHIR_ALL_READ"},
                    {permission: "FHIR_BATCH"},
                    {permission: "ACCESS_ADMIN_JSON"},
                    {permission: "FHIR_META_OPERATIONS_SUPERUSER"},
                    {permission: "FHIR_OP_ENCOUNTER_EVERYTHING"},
                    {permission: "FHIR_OP_PATIENT_EVERYTHING"},
                    {permission: "FHIR_TRANSACTION"}],
                "password": pass
            };

            // the Practitioner which is autogenerated has the identifier <userId> already, so just add the user
            // create the user on the remote
            try {
                userData = await this.smileService.createUser(userData, FhirService.RemoteSetup.fhirAdmin, UserService.AdminUsers.remote, FhirService.RemoteSetup.fhirUserNodeId, FhirService.RemoteSetup.fhirUserModuleId);
                result.pid = userData.pid;
            } catch (exception) {
                let msg = exception.message || DialogMessages.HttpErrorToString(exception) || JSON.stringify(exception);
                console.warn(msg);
                this.dialogMessages.prompt(msg, this.i18n.tr("warning"), true);
            }
        } else {
            // user found
            this.userId = userId;
        }

        result.userId = this.userId;

        return result;
    }

    async impersonate(userId: string, familyName: string, givenName: string): Promise<any> {
        let result = {isNew: false, user: undefined, practitioner: undefined, role: undefined, userId: undefined};
        sessionStorage.removeItem(this.impersonationKey);
        this.username = userId.toUpperCase();
        if (RuntimeInfo.Embedded && RuntimeInfo.Features.autoUserCreation?.enabled === true) {
            result = await this.autoGenerateUser(this.username, familyName, givenName);
        }

        this.ignoreLogin = true;
        await this.loadData(this.username, givenName, familyName);

        if (this.practitioner) {
            sessionStorage.setItem(this.impersonationKey, userId);
            UserService.Practitioner =this.practitioner;
            UserService.Role = this.practitionerRole;
        }

        result.practitioner = this.practitioner;
        result.role = this.practitionerRole;
        result.userId = userId;

        return result;
    }

    userId: string;
    public static ValidationRequired: boolean = true;

    async doPrincipaLogin(username: string, password: string, storeHash: boolean = true): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            let loginObject = {};
            loginObject[UserService.Login.userNameProperty] = username;
            loginObject[UserService.Login.passwordProperty] = password;

            await this.dialogService.closeAll();
            let url = UserService.Login.loginUrl.replace(/%USER%/gi, username).replace(/%PASS%/gi, password);
            if (storeHash) {
                UserService.IsLoggedIn = false;
            }

            $.ajax({
                method: UserService.Login.loginMethod.toUpperCase(),
                url: url, // NitTools.IncludeTrailingSlash(UserService.Login.loginUrl),
                data: JSON.stringify(loginObject),
                dataType: "application/json",
                contentType: 'application/json',
                statusCode: {
                    0: (result) => {
                        let cors = `Access to XMLHttpRequest <br />at "${UserService.Login.loginUrl}" <br />has been blocked by CORS policy!`;
                        let msg = this.i18n.tr("could_not_connect_login_server");
                        if (ConfigService.Debug) {
                            msg += result.statusText ? `<br /><div class="bg-warning text-warning"><span style="font-size: 0.7em">\nStatus: ${cors}</span></div>` : '';
                        }

                        resolve({success: false, error: msg});
                    },
                    200: async (strResult) => {
                        let result = JSON.parse(strResult.responseText);
                        let error = result[UserService.Login.errorFieldName];
                        if (error) {
                            reject(error);
                        } else {
                            let loginObject = resolve({
                                success: true,
                                error: undefined,
                                sessionId: result[UserService.Login.tokenFieldName]
                            });

                            if (!storeHash) {
                                return loginObject;
                            }

                            this.userId = String(result["userId"]).toUpperCase();
                            let roleId = result["practitionerRoleId"];
                            this._username = String(username).toUpperCase();
                            FhirService.Hash = btoa(`${username}:${password}`); // result[UserService.Login.tokenFieldName];
                            sessionStorage.setItem(environment.sessionName, FhirService.Hash);
                            sessionStorage.setItem(environment.principaSessionName, result.sessionId);

                            UserService.IsLoggedIn = true;

                            try {
                                await this.loadPractitioner();
                            } catch (e) {
                                console.warn(e.message || e);
                            }

                            await this.setWards(false);
                            resolve(loginObject);
                        }
                    },
                    401: (result) => {
                        resolve({success: false, error: this.i18n.tr("login_failed")});
                    },
                    404: (result) => {
                        resolve({success: false, error: UserService.Login.loginUrl + ' not found'});
                    },
                    500: result => {
                        /* if (ConfigService.Debug) {
                            msg += result.responseText ? `<br /><div class="bg-warning text-warning"><span style="font-size: 0.7em">\nStatus: ${result.responseText}</span></div>` : '';
                        } */

                        resolve({success: false, error: this.i18n.tr("login_server_returned_error")});
                    }
                }
            });
        });
    }

    private urlHasSessionId(): boolean {
        // login in when embedded and sessionId is set:
        if (/sessionId/gi.test(window.location.href)) {
            let arr = String(window.location.href).match(/[(\?|\&)]([^=]+)\=([^&#/]+)/g);
            for (let i = 0; i < arr.length; i++) {
                if (arr[i][0] === "&" || arr[i][0] === "?") arr[i] = arr[i].substr(1);

                if (/sessionId=/i.test(arr[i])) {
                    let val = arr[i].split('=')[1];
                    FhirService.Hash = decodeURIComponent(val);
                    sessionStorage.setItem(environment.sessionName, val);
                    return true;
                }
            }
        }
    }

    public static get Hash(): string {
        return FhirService.Hash;
    }

    public isAdmin: boolean = false;

    public getCurrentUserRole() {
        let s = sessionStorage.getItem('userRole');
        if (!s) return undefined;
        return atob(s);
    }

    get syncUserEnabled(): boolean {
        if (ConfigService.Debug && !ConfigService.IsTest) {
            console.debug(`!!FhirService.RemoteSetup.fhirServer ["${FhirService.RemoteSetup.fhirServer}"] = ${!!FhirService.RemoteSetup.fhirServer}`);
            console.debug(`!!FhirService.RemoteSetup.fhirAdmin ["${FhirService.RemoteSetup.fhirAdmin}"] = ${!!FhirService.RemoteSetup.fhirAdmin}`);
            console.debug(`!!FhirService.RemoteSetup.fhirUserNodeId ["${FhirService.RemoteSetup.fhirUserNodeId}"] = ${!!FhirService.RemoteSetup.fhirUserNodeId}`);
            console.debug(`!!FhirService.RemoteSetup.fhirUserModuleId ["${FhirService.RemoteSetup.fhirUserModuleId}"] = ${!!FhirService.RemoteSetup.fhirUserModuleId}`);
            console.debug(`UserService.AdminUsers ["${UserService.AdminUsers}"] = ${!!UserService.AdminUsers}`);
            if (UserService.AdminUsers) {
                console.debug(`!!UserService.AdminUsers.remote ["${UserService.AdminUsers.remote}"] = ${!!UserService.AdminUsers.remote}`);
                console.debug(`!!UserService.AdminUsers.remote ["${UserService.AdminUsers.local}"] = ${!!UserService.AdminUsers.local}`);
            }
        }

        return !!FhirService.RemoteSetup.fhirServer && !!FhirService.RemoteSetup.fhirAdmin && !!FhirService.RemoteSetup.fhirUserNodeId &&
            !!FhirService.RemoteSetup.fhirUserModuleId &&
            UserService.AdminUsers && !!UserService.AdminUsers.remote && !!UserService.AdminUsers.local;
    }

    async doesUserExist(admin, username, managementUser) {
        let client = new HttpClient();
        let url = `${admin}?searchTerm=${encodeURIComponent(username)}`;
        let result: any = await client.createRequest(url).withBaseUrl(admin).withHeader('Authorization', `Basic ${managementUser}`).asGet().send();
        result = JSON.parse(result.response);
        if (result.users) result = result.users;

        if (NitTools.IsArray(result)) {
            if (!result) result = [];
            let existingUser = result.find(o => o.username === username.toUpperCase());
            return typeof existingUser !== "undefined";
        }

        return false;
    }

    async doesUserExistLocal(username) {
        return this.doesUserExist(`${FhirService.AdminEndpoint}/user-management/${FhirService.UserNodeId}/${FhirService.UserModuleId}`, username, UserService.AdminUsers.local);
    }

    async loadRemoteUser(userName) {
        return await this.smileService.getRemoteUser(userName);
    }

    async doesRemoteLoginSucceed(username, password) {
        // try a login to be sure that username and password are correct
        return this.fhirService.tryLogin(btoa(`${username}:${password}`), FhirService.RemoteSetup.fhirServer);
    }

    public updateUser(user: ISmileUser): Promise<ISetUserPasswordResult> {
        return new Promise<ISetUserPasswordResult>(async (resolve) => {
            let result: ISetUserPasswordResult = {success: false};
            let url = `${FhirService.RemoteSetup.fhirAdmin}/user-management/${user.nodeId}/${user.moduleId}/${user.pid}`;
            let client = this.smileService.getClient(FhirService.RemoteSetup.fhirAdmin, UserService.AdminUsers.remote);
            client.createRequest(url).asPut().withContent(user).send()
                .catch(error => {
                    result.error = DialogMessages.HttpErrorToString(error, this.i18n.tr('error_setting_pass'));
                    result.success = false;
                    resolve(result);
                })
                .then(() => {
                    result.success = true;
                    resolve(result);
                });
        });
    }

    public static AdminUsers: {
        "remote": string;
        "local": string;
    };

    public static Login = {
        "usePrincipa": false,
        "useFhirLogin": true,
        "loginUrl": "https://cluster.siegele-software.com/rest/authorize?user=%USER%&password=%PASS%",
        "loginMethod": "GET",
        "userNameProperty": "login",
        "passwordProperty": "password",
        "tokenFieldName": "sessionId",
        "errorFieldName": "error",
        "oauth2": {
            "enabled": false,
            "endpoint": "http://vmd73685.contaboserver.net:9200",
            "payload": {
                "grant_type": "password",
                "client_id": "ciw",
                "client_secret": "ciw-secret",
                "scope": "openid+profile+patient/*.read"
            }
        }
    };

    async getPrincipaLogin(user, pass): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                let result = await this.doPrincipaLogin(user, pass, false);
                return resolve(result);
            } catch (e) {
                return reject(e.message || e);
            }
        });
    }

    async login(username: string, password: string): Promise<any> {
        let errorMessage: string;
        UserService.Practitioner = undefined;
        UserService.Role = undefined;
        UserService.__UserRole = undefined;

        await this.idLogikService.login();

        //#region handle oauth2 login
        if (ConfigService.UseOAuth2) {
            try {
                let sessionToken: any = sessionStorage.getItem(environment.oauth2SessionName);
                if (sessionToken) {
                    sessionToken = JSON.parse(sessionToken);
                } else {
                    try {
                        sessionToken = await this.loginOAuth(username, password);
                    } catch (ex) {
                        const response = JSON.parse(ex.response);
                        errorMessage = ex.response;
                        if (response.error)
                            errorMessage = response.error;
                        if (response.error_description)
                            errorMessage = response.error_description;

                        throw (errorMessage);
                    }
                }

                ConfigService.SetOAuthTokenProperties(sessionToken);

                sessionToken.success = true;
                await this.loadData(username);
                sessionStorage.setItem('userRole', btoa(this.isAdmin ? 'admin' : (UserService.UserRole || 'user')));
                if (this.practitioner)
                    sessionStorage.setItem('PractitionerId', this.practitioner.id);

                this.isLoggedIn = true;

                return Promise.resolve(sessionToken);
            } catch (ex) {
                return Promise.resolve({success: false, message: ex});
            }
        }
        //#endregion

        //#region login in when using principa and sessionId is set:
        let search = window.location.search || "?foo=bar";
        if (UserService.Login.usePrincipa && /sessionId/gi.test(search)) {
            let arr = String(window.location.href).match(/[(\?|\&)]([^=]+)\=([^&#/]+)/g);
            for (let i = 0; i < arr.length; i++) {
                if (arr[i][0] === "&" || arr[i][0] === "?") arr[i] = arr[i].substr(1);

                if (/sessionId=/i.test(arr[i])) {
                    let val = arr[i].split('=')[1];
                    FhirService.Hash = val;
                    sessionStorage.setItem(environment.sessionName, val);
                    break;
                }
            }

            UserService.IsLoggedIn = true;
            this._username = "Pflege";
            this._firstName = "Principa";
            this._lastName = "User";
            this._noLogin = true;

            return Promise.resolve();
        }
        //#endregion

        //#region login when just using principa (no sessionId)
        if (UserService.Login.usePrincipa) {
            try {
                let result = await this.doPrincipaLogin(username, password);
                if (result.success) {
                    sessionStorage.setItem(environment.sessionName, btoa(`${username}:${password}`));
                    sessionStorage.setItem(environment.principaSessionName, result.sessionId);
                    FhirService.Hash = btoa(`${username}:${password}`);
                }

                await this.loadData();
                return await Promise.resolve(result);
            } catch (e) {
                return await Promise.reject(e.message || e);
            }
        }
        //#endregion

        //#region enable Tiplu Login
        if (ConfigService.Tiplu.enabled && ConfigService.Tiplu.token) {
            try {
                await this.loadData(username);
                return {
                    success: !!UserService.Practitioner,
                    message: UserService.Practitioner?undefined:'Practitioner could not be loaded'
                };
            }
            catch (ex) {
                console.warn(ex);
                return {success: false, message: ex};
            }
        }
        //#endregion

        //#region Default Login Procedure
        const hash = btoa(username + ':' + password);

        let result = await this.fhirService.tryLogin(hash);

        if (!result && this.syncUserEnabled) {  // check whether syncing the users is enabled by enough settings
            let userExistsLocal = false;
            if (!await this.smileService.checkFhirHealth(FhirService.Endpoint)) {
                return {
                    success: false,
                    error: this.i18n.tr('fhir_server_did_not_answer').replace('%SERVER%', FhirService.Endpoint + ' (Fhir)')
                };
            }

            if (!await this.smileService.checkFhirHealth(FhirService.AdminEndpoint)) {
                return {
                    success: false,
                    error: this.i18n.tr('fhir_server_did_not_answer').replace('%SERVER%', FhirService.AdminEndpoint + ' (Admin)')
                };
            }

            // login was unsuccessful.
            // Now find out, using the user-admin user, if :
            // a) the user exists and it was a wrong password or
            // b) if the user is non existent on local
            userExistsLocal = await this.doesUserExistLocal(username);
            if (ConfigService.Debug) console.debug("User exists local:" + userExistsLocal);

            if (!userExistsLocal) {
                let currentServer = "Fhir-Server";
                let isAlive = await this.smileService.checkFhirHealth(FhirService.RemoteSetup.fhirServer);

                currentServer = "Fhir-Admin";
                isAlive = isAlive && await this.smileService.checkFhirHealth(FhirService.RemoteSetup.fhirAdmin);

                if (!isAlive) {
                    return {
                        success: false,
                        error: this.i18n.tr('remote_server_timed_out').replace('%SERVER%', currentServer)
                    };
                }

                // check if the user exists on remote server by simply trying a "login"-procedure
                let remoteLoginOk = await this.doesRemoteLoginSucceed(username, password);

                FhirService.Hash = undefined;
                if (remoteLoginOk) {
                    // when we reach this, the user exists on remote fhir, but not on the local.
                    // so let's create the user, and add the existing practitionerRole and existing practitioner. Use Put to maintain the IDs
                    let userData = undefined;
                    try {
                        userData = await this.smileService.getUser(username, FhirService.RemoteSetup.fhirAdmin, UserService.AdminUsers.remote);

                        if (!userData) {
                            throw new Error(this.i18n.tr("user_could_not_be_read"));
                        } else {
                            // we got a user from the administration.
                            // So we need to set the current LOCAL nodeId and moduleId to be able to store him/her
                            userData.nodeId = FhirService.UserNodeId;
                            userData.moduleId = FhirService.UserModuleId;

                            let pracAndRole = await this.smileService.getPractitioner(FhirService.RemoteSetup.fhirServer, UserService.AdminUsers.remote, username);

                            // everything we need has been loaded, so create it locally
                            if (pracAndRole && pracAndRole.practitioner && pracAndRole.role) {
                                // don't forget to assign the given password. As we successfully logged in on the remote Fhir, it is definitively correct
                                userData.password = password;

                                // create the user with the local-admin
                                let createResult = await this.smileService.createUser(userData, FhirService.AdminEndpoint, UserService.AdminUsers.local);

                                // the result is either the created user, or an error object. When failing, bail out of here
                                if (createResult.error) {
                                    throw new Error(this.i18n.tr("could_not_create_user"));
                                }

                                // we can use fhir-service here, because the environment.fhirService is mapped from fhirService.local...
                                // .. just need to set the hash:
                                FhirService.Hash = UserService.AdminUsers.local;

                                // Now we can create the practitioner per update and then ...
                                await this.fhirService.update(pracAndRole.practitioner);

                                // .. create the Role per update ..
                                await this.fhirService.update(pracAndRole.role);

                                // .. then set the hash for the new created user.
                                FhirService.Hash = btoa(`${username.toUpperCase()}:${password}`);

                                // return a success because hopefully all userdata exists
                                return Promise.resolve({success: true});
                            } else {
                                let message = '';

                                // nothing returned?
                                if (!pracAndRole) {
                                    message = pracAndRole ? '' : 'Practitioner- and Role-Bundle not loaded for user';
                                } else {
                                    // no Practitioner?
                                    if (!pracAndRole.practitioner) {
                                        message = 'No Practitioner found<br />';
                                    }

                                    // no Role?
                                    if (!pracAndRole.role) {
                                        message += 'No PractitionerRole found';
                                    }
                                }

                                throw new Error(message);
                            }
                        }
                    } catch (e) {
                        if (e && e.statusCode) {
                            if (e.statusCode === 401) {
                                e.message = this.i18n.tr('usermanager_account_not_authorized') + ' (Error 401)';
                            } else if (e.statusCode >= 500) {
                                e.message = this.i18n.tr('internal_server_error') + ' (Error ' + e.statusCode + ')';
                            }
                        }

                        return Promise.resolve({
                            success: false,
                            error: (e.message || JSON.stringify(e))
                        });
                    }
                } else {
                    // remoteLogin was not OK. Check why, because it may be a user which is autogenerated and may need to set a password first
                    let remoteUser = await this.loadRemoteUser(username.toUpperCase());
                    if (typeof remoteUser !== "undefined" && remoteUser.accountLocked === true) {
                        return Promise.resolve({
                            success: false,
                            error: 'Need to set Password',
                            needsToSetPassword: true
                        });
                    }
                }
            }
        }

        // don't use else. When login was unsuccessful and the user exists, the story **continues** here.
        if (result) {
            // just a plain default login. Authorization was ok, so everyone is happy
            this._username = username; // usernameCaps;
            UserService.IsLoggedIn = true;

            FhirService.Hash = hash;
            sessionStorage.setItem(environment.sessionName, hash);

            await this.loadData();

            try {
                if (!this.practitioner) {
                    try {
                        await this.loadPractitioner();
                    } catch (ex) {
                        console.warn(ex);
                    }
                }

                // check if the practitioner is allowed to login to CIW.
                if (this.practitioner) {
                    if (!this.practitioner.identifier) this.practitioner.identifier = [];

                    let roleName : string = UserService.GetUserRole(this.practitioner);
                    if (typeof roleName === "undefined") {
                        roleName = 'user';
                        this.practitioner.identifier.push({
                            system: `${RuntimeInfo.SystemHeader}/smile-user-role` ,
                            value: roleName
                        });
                    }

                    this.isAdmin = roleName === 'admin';
                    result = true;

                    if (roleName === "designer") {
                        result = false;
                        errorMessage = this.i18n.tr("user_with_this_role_permittet");
                    } else {
                        result = true;
                    }
                }
            } catch (e) {
                console.warn(e.message);
            }
        } else {
            sessionStorage.removeItem('PractitionerId');
            sessionStorage.removeItem('userRole');
            sessionStorage.removeItem(environment.sessionName);
            result = false;
            FhirService.Hash = undefined;
        }

        if (result) {
            sessionStorage.setItem('userRole', btoa(this.isAdmin ? 'admin' : (UserService.UserRole ||'user')));
            if (this.practitioner)
                sessionStorage.setItem('PractitionerId', this.practitioner.id);
        }

        return Promise.resolve({
            success: result,
            error: errorMessage || (result ? undefined : this.i18n.tr('login_failed'))
        });
        //#endregion
    }

    public loginOAuth(user: string, pass: string): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const loginCfg = ConfigService.cfg["login"];
                if (!loginCfg)
                    throw `Property "login" not found in config`;
                const oauthConfig = loginCfg["oauth2"];
                if (!oauthConfig)
                    throw `Property "login.oauth2" not found in config`;

                let client = new HttpClient();
                client.configure(config => {
                    config.withBaseUrl(oauthConfig.endpoint + '/oauth')
                        .withHeader('Content-Type', 'application/x-www-form-urlencoded');
                });

                const searchParams = new URLSearchParams();

                for (const key of Object.keys(oauthConfig.payload)) {
                    searchParams.append(key, oauthConfig.payload[key]);
                }

                searchParams.append("username", user);
                searchParams.append("password", pass);

                const result = await client.createRequest("token")
                    .withContent(searchParams.toString())
                    .asPost()
                    .send();

                if (result.statusCode == 200) {
                    resolve(result.content);
                } else {
                    reject(`[${result.statusCode}]: ${result.statusText}`);
                }
            } catch (ex) {
                reject(ex);
            }
        });
    }

    public async refreshToken() {
        if (!ConfigService.RefreshToken || !ConfigService.AccessToken) {
            console.warn("Needed tokens for refresh not present in ConfigService! Not trying to refresh the Token");
            return;
        }

        if (!UserService.IsLoggedIn)
            return;

        if (ConfigService.Debug) {
            console.debug(`Refreshing Tokens`);
        }

        const client = new HttpClient();
        const oauthConfig = ConfigService.cfg["login"]["oauth2"];

        client.configure(x => {
            const basic = btoa(`${oauthConfig.payload.client_id}:${oauthConfig.payload.client_secret}`);
            x.withBaseUrl(oauthConfig.endpoint)
                .withHeader("Accept", "application/json")
                .withHeader('Content-Type', 'application/x-www-form-urlencoded')
                //.withHeader('Authorization', `Bearer ${ConfigService.AccessToken}`)
                .withHeader('Authorization', `Basic ${basic}`);
        });

        const params = new URLSearchParams();
        params.append("grant_type", "refresh_token");
        params.append("refresh_token", ConfigService.RefreshToken);
        params.append("client_id", oauthConfig.payload.client_id);

        try {
            const result = await client.createRequest("oauth/token").withContent(params.toString()).asPost().send();
            if (result.statusCode === 200) {
                ConfigService.SetOAuthTokenProperties(result.response);
                this.checkOAuthLoginTimer();
            }
        } catch (result) {
            // smile seems to have a bug when refreshing tokens the 3rd time. So this is a workaround
            // in which we try to completely relogin the current user from the stored user/pass-
            // this only works if the user has been logged in from the login-screen. When the OAuth-Code
            // has been provided the password is set to "dummy" so this workaround will not function.
            console.warn("Error when refreshing Token!", result);
            const storedCreds = sessionStorage.getItem(environment.sessionName);
            if (storedCreds) {
                const [u, p] = atob(storedCreds).split(':');
                if (p !== "dummy") {
                    try {
                        const tokenData = await this.loginOAuth(u, p);
                        ConfigService.SetOAuthTokenProperties(tokenData);
                        console.warn("Successfully relogged in");
                        this.checkOAuthLoginTimer();
                    } catch (ex) {
                        console.warn("Error when relogging in: ", ex);
                    }
                } else {
                    console.warn("Could not relogin, Dummy Password has been found");
                }
            } else {
                console.warn("Could not relogin, because no credentials found");
            }
        }
    }

    refreshTimerId: number;
    tokenUpdateListeners = [];
    
    public addTokenUpdateListener(listener: () => void): () => void {
        this.tokenUpdateListeners.push(listener);
    
        return () => {
            const idx = this.tokenUpdateListeners.indexOf(listener);
            if (idx >= 0) {
                this.tokenUpdateListeners.splice(idx, 1);
            }
        };
    }

    public callTokenUpdateListeners() {
        this.tokenUpdateListeners.forEach(x => x());
    }

    public async forceRefreshToken() {
        if (ConfigService.UseOAuth2) {
            if (ConfigService.Debug)
                console.debug("forcing OAuth2 login");

            ConfigService.EnsureTokens();

            if (UserService.IsLoggedIn && ConfigService.TokenExpirationSeconds) {
                if (this.refreshTimerId) {
                    window.clearTimeout(this.refreshTimerId);
                    this.refreshTimerId = undefined;
                }

                await this.refreshToken();
                this.callTokenUpdateListeners();
            }
        }
    }

    checkOAuthLoginTimer() {
        if (ConfigService.UseOAuth2) {
            if (ConfigService.Debug)
                console.debug("checking OAuth2 login");

            ConfigService.EnsureTokens();

            if (UserService.IsLoggedIn && ConfigService.TokenExpirationSeconds) {
                if (!this.refreshTimerId) {
                    if (ConfigService.Debug) {
                        console.debug(`Refreshing Token in ${ConfigService.TokenExpirationSeconds - 10} Seconds`);
                    }

                    this.refreshTimerId = window.setTimeout(async () => {
                        window.clearTimeout(this.refreshTimerId);
                        this.refreshTimerId = undefined;
                        await this.refreshToken();
                        this.callTokenUpdateListeners();
                    }, (ConfigService.TokenExpirationSeconds - 10) * 1000);
                } else if (ConfigService.Debug) {
                    console.debug("a scheduled refresh already exists.");
                }
            }
        }
    }

    async codeTest(user, pass) {
        const client = new HttpClient();
        const oauthConfig = ConfigService.cfg["login"]["oauth2"];
        client.configure(x => {
            const basic = btoa(`${oauthConfig.payload.client_id}:${oauthConfig.payload.client_secret}`);
            x.withBaseUrl(oauthConfig.endpoint)
                .withHeader("Accept", "application/json")
                .withHeader('Content-Type', 'application/x-www-form-urlencoded')
                .withHeader('Authorization', `Basic ${basic}`);
        });

        const params = new URLSearchParams();
        params.append("state", "af0ifjsldkj");
        params.append("grant_type", "password");
        params.append("scope", oauthConfig.payload.scope);
        params.append("response_type", "code");
        params.append("client_id", oauthConfig.payload.client_id);
        params.append("client_secret", oauthConfig.payload.client_secret);
        params.append("redirect_uri", "http%3A%2F%2F207.180.250.172%2Fsempa");
        params.append("username", user);
        params.append("password", pass);

        try {
            const result = await client.createRequest("oauth/token").withContent(params.toString()).asPost().send();
            if (ConfigService.Debug)
                console.debug("OK", result);
        } catch (ex) {
            console.warn(ex);
        }
    }

    public loginOAuthCode(code: string): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const loginCfg = ConfigService.cfg["login"];
                const oauthConfig = loginCfg["oauth2"];

                let client = new HttpClient();
                client.configure(config => {
                    config.withBaseUrl(oauthConfig.endpoint + '/oauth')
                        .withHeader('Content-Type', 'application/x-www-form-urlencoded')
                        .withHeader('Authorization', `Basic ${btoa(oauthConfig.payload["client_id"] + ':' + oauthConfig.payload["client_secret"])}`);
                });

                const searchParams = new URLSearchParams();
                searchParams.append(`client_id`, oauthConfig.payload["client_id"]);
                searchParams.append(`grant_type`, `authorization_code`);
                searchParams.append(`code`, code);
                let path = window.location.pathname;
                if (path.endsWith('/'))
                    path = path.substring(0, path.length - 1);

                searchParams.append('redirect_uri', window.location.origin + path);

                const result = await client.createRequest("token")
                    .withContent(searchParams.toString())
                    .asPost()
                    .send();

                if (result.statusCode == 200) {
                    resolve(result.content);
                } else {
                    reject(`[${result.statusCode}]: ${result.statusText}`);
                }
            } catch (ex) {
                console.warn(ex);
                reject(ex);
            }
        });
    }

    async checkLogin() {
        let result = {
            success: false,
            needsToSetPassword: false
        };

        let isEmbedded = /embedded=1/gi.test(window.location.href) || RuntimeInfo.Embedded;
        if (this._noLogin && !isEmbedded) {
            if (!this._isDataLoaded) {
                await this.loadData();
            }
        }

        if (!UserService.IsLoggedIn) {
            if (isEmbedded) {
                sessionStorage.setItem(environment.sessionName, FhirService.EmbeddedUserHash);
            }

            result.success = false;

            ConfigService.UseOAuth2 = ConfigService.cfg && ConfigService.cfg.login
                && ConfigService.cfg.login.oauth2
                && ConfigService.cfg.login.oauth2.enabled === true
                && window.location.href.indexOf('oauth=0') === -1;

            if (ConfigService.UseOAuth2) {
                let oa: any;
                try {
                    const dta = sessionStorage.getItem(environment.oauth2SessionName);
                    if (dta) {
                        oa = result = JSON.parse(dta);
                    }
                } catch (ex) {
                    console.warn(ex);
                    oa = undefined;
                }

                result.success = !!oa;
                if (oa && oa.access_token) {
                    ConfigService.SetOAuthTokenProperties(oa);

                    const userPass = sessionStorage.getItem(environment.sessionName);
                    if (userPass) {
                        this.username = atob(userPass).split(':')[0];
                    }

                    if (!this._isDataLoaded)
                        await this.loadData();

                    UserService.IsLoggedIn = true;
                    // when no timer is running request a new token because we do not know
                    // how old the existing one is and it may expire during usage.
                    // this will although set up the refresh-timer
                    if (!this.refreshTimerId) {
                        await this.refreshToken();
                    }
                }

                result.needsToSetPassword = false;
            } else {
                const sessionData = sessionStorage.getItem(environment.sessionName);

                if (sessionData) {
                    const [username, password] = atob(sessionData).split(':');
                    const login = await this.login(username, password);

                    if (!login || !login.success) {
                        this.logout();
                        result.success = false;
                        result.needsToSetPassword = login.needsToSetPassword;
                        UserService.IsLoggedIn = false;
                    } else {
                        this._username = username;
                        UserService.IsLoggedIn = true;
                        await this.loadPractitioner();

                        result.success = true;
                    }
                }
            }

            return result;
        }

        result.success = true;
        return result;
    }

    logout(redirect = false) {
        if (this._noLogin) {
            return;
        }

        this.idLogikService.logout();

        UserService.IsLoggedIn = false;
        FhirService.Hash = undefined;
        sessionStorage.clear();

        ConfigService.AccessToken = undefined;
        ConfigService.IdToken = undefined;
        ConfigService.RefreshToken = undefined;
        ConfigService.TokenExpirationSeconds = 0;
        ConfigService.TokenType = undefined;

        if (redirect) {
            RuntimeInfo.IsLoading = true;
            if (this.router.navigate('login'))
                window.location.reload();
        }
    }

    async updateSelectedWard(wardId) {
        if (UserService.Login.usePrincipa || (this.defaultWard && wardId === this.defaultWard.id)) {
            return;
        }

        this.defaultWard = this.wards.find(ward => ward.id === wardId);

        if (this._noLogin) {
            return;
        }

        this.practitionerRole.location.forEach((location) => {
            const [resourceType, locationId] = location.reference.split('/');
            const ward = this.locationService.getLocationById(locationId);

            if (location.identifier && location.identifier.system === RuntimeInfo.SystemHeader + '/practitioner-default-ward') {
                delete location.identifier;
            }

            if (locationId === wardId) {
                location.identifier = {
                    system: RuntimeInfo.SystemHeader + '/practitioner-default-ward',
                    value: 'default'
                };
            }

            return ward;
        });

        await this.fhirService.update(this.practitionerRole);
    }

    ignoreLogin: boolean = false;

    async loadWardsToLocationService(username: string, password: string) {
        let hash = sessionStorage.getItem(environment.principaSessionName);
        if (!hash) {
            if (RuntimeInfo.DataProxy.isPrincipa) {
                hash = sessionStorage.getItem(environment.principaSessionName);
                if (!hash) {
                    let loginData: { success: boolean, error: any, sessionId: string } = await this.getPrincipaLogin(username, password);
                    if (loginData && loginData.success === true && loginData.sessionId) {
                        hash = loginData.sessionId;
                    } else {
                        throw loginData.error || 'Could not get Bearer-Token from ' + UserService.Login.loginUrl;
                    }
                }
            } else {
                hash = btoa(`${username}:${password}`);
            }

            sessionStorage.setItem(environment.principaSessionName, hash);
        }

        let _locations: any[] = await PumpService.GetLocations(hash);
        _locations.sort((a, b) => {
            return a.name.localeCompare(b.name);
        });

        this.locationService.clear();
        _locations.forEach(l => this.locationService.insertLocation(l));
    }

    private static _fetchingUser;

    private static waitForUserService(): Promise<any> {
        return new Promise<any>(async (resolve) => {
            if (!this._fetchingUser) {
                resolve(true);
            } else {
                window.setTimeout(async () => {
                    await this.waitForUserService();
                    resolve(true);
                }, 100);
            }
        });
    }

    async loadData(userName?: string, firstName? : string, lastName? : string) {
        await UserService.waitForUserService();

        let step = "start";

        if (!this.username && userName) this.username = userName;

        try {
            if ((!this._noLogin || this.ignoreLogin) && typeof this.username === "string") {
                //  this.locationService.clear();

                step = "Practitioner";
                await this.loadPractitioner(firstName, lastName);

                if (RuntimeInfo.DataProxy && RuntimeInfo.DataProxy.enabled === true && this.username.toUpperCase() !== "ROOT") {
                    let params = NitTools.GetUrlParams();
                    if (params.sessionId) {
                        sessionStorage.setItem(environment.principaSessionName, params.sessionId);
                    }

                    let [user, pass] = atob(sessionStorage.getItem(environment.sessionName)).split(':');

                    await this.loadWardsToLocationService(user, pass);
                } else {
                    const locationIds = [];
                    if (this.username.toUpperCase() !== "ROOT" && NitTools.IsArray(UserService.Role?.location)) {
                        for (const location of UserService.Role?.location) {
                            const locationId = NitTools.GetId(location);
                            if (locationId) {
                                locationIds.push(locationId);
                            }
                        }
                    }

                    await this.locationService.fetch(true, locationIds);
                }
            }

            step = "set Wards";
            await this.setWards(this._noLogin);
            this._isDataLoaded = true;
        } catch (e) {
            console.warn(e.message || e);
            this._isDataLoaded = false;
            throw (this.i18n.tr("error_loading_user_data").replace("%ERROR%", (e.message || e)) + (ConfigService.Debug ? ` <br />DEBUG: step = "${step}"` : ""));
        }
    }

    public async loadPractitioner(firstName? : string, lastName? : string) {
        let uri = `Practitioner?identifier=${(UserService.Login.usePrincipa ? this.userId : this.username.toUpperCase())}&_revinclude=PractitionerRole:practitioner${ConfigService.Tiplu.enabled? '' : '&active=true'}`;

        let practitionerResult = await (UserService.Login.usePrincipa
            ? PumpService.GetResources(uri, sessionStorage.getItem(environment.principaSessionName))
            : this.fhirService.fetch(uri));

        this.practitioner = <any>practitionerResult.find(res => res.resourceType === 'Practitioner');
        this.practitionerRole = <any>practitionerResult.find(res => res.resourceType === fhirEnums.ResourceType.practitionerRole);

        // try to get the practitioner by names
        if (ConfigService.cfg?.features?.searchForPractitionerName === true && !this.practitioner && firstName && lastName) {
            const uri = `Practitioner?name=${firstName}&name=${lastName}&_revinclude=PractitionerRole:practitioner&active=true`;
            practitionerResult = await (UserService.Login.usePrincipa
                ? PumpService.GetResources(uri, sessionStorage.getItem(environment.principaSessionName))
                : this.fhirService.fetch(uri));

            this.practitioner = practitionerResult.find((res) => res.resourceType === 'Practitioner');
            this.practitionerRole = practitionerResult.find((res) => res.resourceType === fhirEnums.ResourceType.practitionerRole);
        }

        if (this.practitioner) {
            sessionStorage.setItem('PractitionerId', this.practitioner.id);
            let names = (<any>this.practitioner).name;
            if (names && names.length > 0) {
                let official = names.find(o => o.use === "official");
                if (!official) official = names[0];
                this._lastName = official.family;
                this._firstName = official.given?.join(', ');
            }

            await this.setWards(false);
            await this.permissionService.fetch(this.practitioner);
        } else {
            sessionStorage.removeItem('PractitionerId');
        }
    }

    private async loadPractitionerRoleById(id: string) {
        this.practitionerRole = <any>await this.fhirService.get(fhirEnums.ResourceType.practitionerRole + "/" + id);
        await this.setWards(false);
    }

    private async setWards(all = false) {
        if (FhirService.OfflineClientSettings && FhirService.OfflineClientSettings.enabled === true && FhirService.OfflineClientSettings.isOffline === true) {
            if (ConfigService.Debug) console.debug('Overriding Location Security because this is a local database');
            all = true;
        }

        if (all) {
            this.wards = this.locationService.wards.slice();
            this.defaultWard = this.wards[0];
        } else {
            if (this.practitionerRole) {
                if (!this.practitionerRole.location) {
                    console.warn("No location found in Practitioner Role:", this.practitionerRole);
                    this.wards = [];
                    return;
                }

                let ws = [];
                this.practitionerRole.location.forEach(l => {
                    let wd = this.locationService.getLocationById(l.reference);
                    if (wd) ws.push(wd);

                    if (l.identifier && l.identifier.system.endsWith('/practitioner-default-ward')) {
                        this.defaultWard = wd;
                    }
                });
                this.wards = ws;
            } else {
                if (this.username !== 'ROOT' && this.username !== 'ADMIN')
                    console.warn("No Practitioner Role found for", this.practitioner);
                
                this.wards = [];
            }
        }
    }
}

export interface ISetUserPasswordResult {
    success: boolean;
    error?: string;
}
