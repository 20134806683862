import { autoinject, } from "aurelia-framework";
import { FEATURES as _FEATURES, type RBACFeature, type RBACPathMap } from "resources/classes/PermissionFeatures";
import { DialogMessages } from "./DialogMessages";
import { FhirService } from "./FhirService";
import { I18N } from "aurelia-i18n";
import { ConfigService } from "./ConfigService";

const FEATURE_SYSTEM = 'http://hospital.com/fhir/CodeSystem/rbac-features';

type Feature = {
  [key: string]: Parameter
}

type Parameter = {
  type: 'coding';
  values: fhir4.Coding[]
} | {
  type: 'canonical';
  values: fhir4.Coding[]
}

@autoinject
export class PermissionService {
  static readonly FEATURES = _FEATURES;
  static readonly STORAGE_KEY = 'rbac-active-role';
  static readonly CONFIG_RBAC_KEY = 'rbacEnabled';

  private fhirService: FhirService;
  private features: { [key: string]: Feature } = {};
  private userRoles: fhir4.Group[] = [];
  private _activeUserRole: fhir4.Group | null = null;
  private isSynced = false;

  public static Instance: PermissionService;

  constructor(private dialogMessages: DialogMessages, private i18n: I18N) {
    this.fhirService = new FhirService();

    PermissionService.Instance = this;
  }

  get numAvailableRoles() {
    return this.userRoles?.length || 0;
  }

  get activeUserRole() {
    return this._activeUserRole ? {
      id: this._activeUserRole.id,
      name: this._activeUserRole.name,
    } : null;
  }

  get isRolesEnabled() {
    return ConfigService.RbacEnabled || ConfigService.cfg[PermissionService.CONFIG_RBAC_KEY];
  }

  getUserRoles() {
    return this.userRoles.map(role => ({
      id: role.id,
      name: role.name,
    }));
  }

  checkActiveUserRole() {
    if (!this.isRolesEnabled) {
      return true;
    }

    if (sessionStorage.getItem(PermissionService.STORAGE_KEY)) {
      return this.assignUserRole(sessionStorage.getItem(PermissionService.STORAGE_KEY));
    } else if (this.userRoles.length === 1) {
      return this.assignUserRole(this.userRoles[0].id);
    }

    return false;
  }

  assignUserRole(roleId: string) {
    this._activeUserRole = this.userRoles.find(role => role.id === roleId);

    if (!this._activeUserRole) {
      return false;
    }

    sessionStorage.setItem(PermissionService.STORAGE_KEY, this._activeUserRole.id);

    this.features = {};
    this._activeUserRole.characteristic?.forEach(characteristic => {
      const coding = characteristic.code?.coding?.[0];

      if (coding.system === FEATURE_SYSTEM) {
        const [feature, parameter] = coding.code.split(':');

        if (!this.features[feature]) {
          this.features[feature] = {};
        }

        if (parameter) {
          const parameterType = characteristic.code?.text?.toLowerCase();

          switch (parameterType) {
            case 'coding':
              this.features[feature][parameter] = {
                type: 'coding',
                values: characteristic.valueCodeableConcept?.coding
              };
              break;
            case 'canonical':
              this.features[feature][parameter] = {
                type: 'canonical',
                values: characteristic.valueCodeableConcept?.coding
              };
              break;
          }
        }
      }
    });

    return true;
  }

  private async sync() {
    function recursiveMapFeatureToConcept (
      feature: any,
      concept: fhir4.CodeSystemConcept[]
    ) {
      Object.keys(feature).forEach((key) => {
        if (['_state', '_path'].includes(key)) {
          return;
        }
  
        const subConcept: fhir4.CodeSystemConcept[] = [];
  
        if (feature[key]._state.type === 'feature') {
          concept.push({
            code: feature[key]._path,
            display: feature[key]._state.title,
            property: [{
              code: 'type',
              valueCode: 'feature'
            }],
            concept: subConcept
          });
  
          if (feature[key]._state.parameters) {
            Object.keys(feature[key]._state.parameters).forEach((parameter) => {
              subConcept.push({
                code: `${feature[key]._path}:${parameter}`,
                display: feature[key]._state.parameters[parameter].title,
                property: [{
                  code: 'type',
                  valueCode: 'parameter'
                }, {
                  code: 'parameter-type',
                  valueCode: feature[key]._state.parameters[parameter].type
                }]
              });
            });
          }
        } else if (feature[key]._state.type === 'module') {
          concept.push({
            code: feature[key]._path,
            display: feature[key]._state.title,
            property: [{
              code: 'type',
              valueCode: 'module'
            }],
            concept: subConcept
          });
  
          recursiveMapFeatureToConcept(feature[key], subConcept);
        }
      });
    }

    if (!ConfigService.RbacEnabled) return;

    const [codeSystem]:[fhir4.CodeSystem] = await this.fhirService.fetch(`CodeSystem?url=${FEATURE_SYSTEM}`);

    if (!codeSystem) {
      console.error('CodeSystem not found:', FEATURE_SYSTEM);
      return;
    }

    const featuresKeys = Object.keys(_FEATURES);
    const conceptApp = codeSystem.concept?.findIndex(
      concept => concept.code === featuresKeys[0]
    );
    const concept: fhir4.CodeSystemConcept[] = [];

    if (conceptApp > -1) {
      codeSystem.concept[conceptApp].concept = concept;
    } else {
      codeSystem.concept?.push({
        code: featuresKeys[0],
        concept,
        property: [{
          code: 'type',
          valueCode: 'application'
        }]
      });
    }

    // @ts-ignore
    recursiveMapFeatureToConcept(_FEATURES[featuresKeys[0]], concept);

    await this.fhirService.update(codeSystem);
  }

  async fetch(practitioner) {
    if (ConfigService.Debug && !this.isSynced) {
      await this.sync();
      this.isSynced = true;
    }

    if (ConfigService.RbacEnabled)
        this.userRoles = await this.fhirService.fetch(`Group?member=Practitioner/${practitioner.id}`);
/*    if (ConfigService.Debug)
      console.warn('User Roles:', this.userRoles);*/
  }

  can(permission: RBACPathMap<RBACFeature>, parameter?: {[key: string]: string | ((parameter: Parameter) => boolean)}) {
    if (!this.isRolesEnabled) {
      return true;
    }
    
    const permissionFeature = permission._path;

    if (!this.features[permissionFeature]) {
      return false;
    }

    if (parameter) {
      for (const [key, value] of Object.entries(parameter)) {
        if (!this.features[permissionFeature].hasOwnProperty(key)) {
          continue;
        }

        if (typeof value === 'function') {
          if (!value(this.features[permissionFeature][key])) {
            return false;
          }
        } else {
          if (this.features[permissionFeature][key].type === 'coding') {
            if (!this.features[permissionFeature][key].values.find(coding => coding.code === value)) {
              return false;
            }
          } else if (this.features[permissionFeature][key].type === 'canonical') {
            const [url, version] = value.split('|');

            if (!this.features[permissionFeature][key].values.find(coding => coding.system === url && (!version || !coding.version || coding.version === version))) {
              return false;
            }
          }
        }
      }
    }

    return true;
  }

  alert(message: string) {
    return this.dialogMessages.prompt(message, this.i18n.tr("error"), true);
  }

  // shorthand can + alert
  canAlert(permission: RBACPathMap<RBACFeature>, parameter?: {[key: string]: string | ((parameter: Parameter) => boolean)}, message?: string) {
    if (!this.can(permission, parameter)) {
      if (!message)
        message = this.i18n.tr('permissions_deny_title');

      if (ConfigService.Debug) {
        message += `<br />Debug: Permission: ${permission._path}`;
        if (parameter) {
          for (const [key, value] of Object.entries(parameter)) {
            message += `<br />Parameter: ${key}: ${value}`;
          }
        }
      }

      this.alert(message);

      return false;
    }

    return true;
  }
}
