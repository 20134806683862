import {HttpClient, HttpResponseMessage} from "aurelia-http-client";
import {I18N} from "aurelia-i18n";
import {ConfigService} from "../../resources/services/ConfigService";
import {PKMS} from "resources/classes/IPKMSItem";
import {PatientItem} from "../classes/Patient/PatientItem";
import {IQuestionnaireList, QuestionnaireService} from "./QuestionnaireService";
import {CarePlanConfigData, Reason} from "../classes/reasonList";
import {fhirEnums} from "../classes/fhir-enums";
import * as Fhir from "../classes/FhirModules/Fhir";
import {DelphiTFormBuilder} from "../classes/DelphiTFormBuilder";
import {ReasonCondition} from "../classes/reasonCondition";
import PKMSReasonGroup = PKMS.PKMSReasonGroup;
import PKMSReasonDetails = PKMS.PKMSReasonDetail;

export class PKMSService {
    static inject = [I18N];

    i18n: I18N;

    constructor(i18n: I18N) {
        this.i18n = i18n;
    }

    private static __groups: PKMSReasonGroup[] = undefined;
    private static __interventions: any[];

    public static Fetch(): Promise<PKMSReasonGroup[]> {
        return new Promise<PKMSReasonGroup[]>((resolve, reject) => {
            if (this.__groups && this.__interventions) {
                this.__groups.forEach(g => g.profiles.forEach(p => {
                    p.selected = false;
                    p.valid = false;
                }));

                resolve(this.__groups);
            } else {
                // new HttpClient().get("./config/pkms-reason-intervention-profiles.json")
                new HttpClient().get("./config/pkms_data.json")
                    .then((r: HttpResponseMessage) => {
                        let globalPkmsObject = JSON.parse(r.response);
                        let tmp: [] = globalPkmsObject["reasonGroups"]; //  JSON.parse(r.response);
                        this.__groups = [];
                        this.__interventions = globalPkmsObject["interventions"];
                        tmp.forEach(t => this.__groups.push(new PKMSReasonGroup(t)));

                        // this.__groups = JSON.parse(r.response);
                        this.__groups.forEach(grp => {
                            if (!ConfigService.IsTest)
                                grp.text = ConfigService.i18n.tr(grp.text);

                            if (grp.profiles) {
                                grp.profiles.forEach(profile => {
                                    if (profile.reasonDetails) {
                                        profile.details = [];
                                        profile.reasonDetails.forEach(o => profile.details.push(new PKMSReasonDetails(o)));
                                    }
                                });
                            }
                        });

                        resolve(this.__groups);
                    })
                    .catch(error => {
                        console.warn(error);
                        reject("Error when downloading intervention profiles");
                    });
            }
        });
    }

    public static GetPKMSReasonsList(patient: PatientItem, qList: IQuestionnaireList): Reason[] {
        let cpcd = new CarePlanConfigData();
        let bmi = true;
        let anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, qList.QAnamnesisId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
        if (anamnesis) {
            let bmiItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, "00_05", false);
            if (bmiItem) {
                let sBmi = Fhir.QuestionnaireResponse.GetResponseItemValue(bmiItem);
                if (sBmi && sBmi !== "") {
                    let valueBmi = parseFloat(sBmi);
                    let item06 = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(anamnesis, "00_06");
                    let value06 = item06 ? Fhir.QuestionnaireResponse.GetKeyValueAsNumber(item06) : -1;
                    bmi = valueBmi <= 35 && value06 === 4;
                }
            }
        }

        return this.GetValidPKMSReasonsForAssessment(patient, cpcd.reasonsList, bmi, qList);
    }


    private static IsConditionValid(condition: string, builder: DelphiTFormBuilder, isValidBmi: boolean): boolean {
        let result = false;

        try {
            let re = condition.split(/[>=<!]/);
            let lo = "VAR_" + re[0];
            let hi = re[re.length - 1];


            let dObj = builder.dataObject;
            if (typeof dObj[lo] === undefined) {
                builder.dataObject[lo] = 0;
            }

            let fhirVal = parseInt(builder.dataObject[lo]);
            let condVal = parseInt(hi);

            if (condition === "BMI") {
                result = isValidBmi;
            } else if (condition.indexOf(">=") > -1) {
                result = fhirVal >= condVal;
            } else if (condition.indexOf('<=') > -1) {
                result = fhirVal <= condVal;
            } else if (condition.indexOf('=') > -1) {
                result = fhirVal == condVal;
            } else if (condition.indexOf('<') > -1) {
                result = fhirVal < condVal;
            } else if (condition.indexOf('>') > -1) {
                result = fhirVal > condVal;
            } else if (condition.indexOf("!=") > -1) {
                result = fhirVal != condVal;
            }
        } catch (e) {
            console.warn(e);
            result = false;
        }

        return result;

    }

    private static IsReasonConditionValid(reasonCondition: ReasonCondition, builder, isValidBmi): boolean {
        let result: boolean = false;
        if (!reasonCondition) return false;
        let conditionsArray: string[] = reasonCondition.values.split(';');
        for (let x = 0; x < conditionsArray.length; x++) {
            let condition = conditionsArray[x];
            let isValid = this.IsConditionValid(condition, builder, isValidBmi);
            if (isValid) {
                if (reasonCondition.operatorType === '' || reasonCondition.operatorType === 'or' || reasonCondition.operatorType === 'oder') {
                    result = true;
                    break;
                } else {
                    if (reasonCondition.operatorType === 'and' || reasonCondition.operatorType === 'und') {
                        result = true;
                    }
                }
            } else {
                // not valid
                if (reasonCondition.operatorType === '' || reasonCondition.operatorType === 'and' || reasonCondition.operatorType === 'und') {
                    result = false;
                    break;
                }
            }
        }

        return result;
    }

    private static isReasonValid(reason: Reason, builder: DelphiTFormBuilder, isValidBmi: boolean): boolean {
        let result = true;
        for (let i = 0; i < reason.conditionList.length; i++) {
            if (this.IsReasonConditionValid(reason.conditionList[i], builder, isValidBmi) === false) {
                result = false;
                break;
            }
        }

        return result;
    }

    public static GetValidPKMSReasonsForAssessment(patient: PatientItem, reasonlist: Reason[], isValidBmi: boolean, qList: IQuestionnaireList): Reason[] {
        let result: Reason[] = [];
        patient.latestAssessment = QuestionnaireService.GetLatestResponseOfType(patient, qList.QAssessmentId, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);
        if (!patient.latestAssessment) return result;

        let pkmsFormBuilder = new DelphiTFormBuilder();
        pkmsFormBuilder.Assessment = patient.latestAssessment;

        reasonlist.forEach(reason => {
            if (this.isReasonValid(reason, pkmsFormBuilder, isValidBmi)) {
                result.push(reason);
            }
        });

        return result;
    }
}
