import {FhirService} from "../FhirService";
import {HttpClient, HttpResponseMessage} from "aurelia-http-client";
import {ConfigService} from "../ConfigService";
import {RuntimeInfo} from "../../classes/RuntimeInfo";
import {NitTools} from "../../classes/NursitTools";

export class FhirServiceMetaTag {
    constructor(protected client: HttpClient) {
        if (!client) client = new HttpClient();
    }

    public value(resource: any, systemEndsWith: string) {
        if (!resource || !resource.meta || !resource.meta.tag) return undefined;

        let tag = resource.meta.tag.find(o => o.system.endsWith(systemEndsWith));
        return tag?.code;
    }

    private paramsResponseToCodingArray(resultMessage: HttpResponseMessage): any[] {
        let result: any[] = undefined;

        if (resultMessage.statusCode === 200) {
            let resultJson: any = JSON.parse(resultMessage.response);
            if (resultJson.parameter) {
                let out = resultJson.parameter.find(o => o.name === "return" && o.valueMeta);
                if (out && out.valueMeta.tag) {
                    result = out.valueMeta.tag;
                }
            }
        } else {
            console.warn(result);
        }

        return result;
    }

    private async doMetaTagPost(resource: any, tag: any, method: string): Promise<any[]> {
        const url = `${resource.resourceType}/${resource.id}/$meta-${method}`;
        if (tag.system.indexOf("http://") === 0) {
            let sys = tag.system.substr(7);  // http://a -> a
            sys = sys.replace(/\/\//g, '/'); //  b//c/d/e -> b/c/d/e
            sys = "http://" + sys;  // add http-protokoll again
            tag.system = sys;
        }

        let parameters: any = {
            resourceType: "Parameters",
            parameter: [
                {
                    name: 'meta',
                    valueMeta: {
                        tag: [tag]
                    }
                }
            ]
        };

        return this.paramsResponseToCodingArray(await this.client.post(url, parameters));
    }

    public async read(resource: any): Promise<any[]> {
        if (resource && resource.meta && resource.meta.tag && NitTools.IsArray(resource.meta.tag) && resource.meta.tag.length > 0) return resource.meta.tag;

        const url = `${resource.resourceType}/${resource.id}/$meta`;
        let resultMessage = await this.client.get(url);
        let result: any[] = this.paramsResponseToCodingArray(resultMessage);

        return result;
    }

    public async delete(resource: any, tag: any, post: boolean = true): Promise<any[]> {
        if (!tag || !resource) return [];
        if (!post) {
            let tagItem = this.get(resource, tag.system);
            if (tagItem) {
                const idx = resource.meta.tag.indexOf(tagItem);
                resource.meta.tag.splice(idx, 1);
            }
        } else {
            let codingResult = await this.doMetaTagPost(resource, tag, 'delete');
            if (codingResult)
                resource.meta.tag = codingResult;
        }

        return resource.meta?.tag;
    }

    public get(resource: any, systemEndsWith: string): any {
        let result = undefined;
        if (resource && resource.meta && resource.meta.tag) {
            result = resource.meta.tag.find(o => o.system.toUpperCase().endsWith(systemEndsWith.toUpperCase()));
        }

        return result;
    }

    public exists(resource: any, systemEndsWith: string): boolean {
        if (!resource || !resource.meta || !resource.meta.tag) return false;
        let result = undefined;
        if (resource && resource.meta && resource.meta.tag) {
            result = resource.meta.tag.find(o => o.system.endsWith(systemEndsWith));
        }

        return !!result;
    }

    public async add(resource: any, tag: any, post: boolean = true): Promise<any[]> {
        if (tag && !/http(s?):/gi.test(tag.system)) {
            tag.system = 'http://' + NitTools.ExcludeTrailingSlash(tag.system);
        }

        if (!post) {
            if (!resource.meta.tag) resource.meta.tag = [];
            resource.meta.tag.push(tag);
        } else {
            let codings = await this.doMetaTagPost(resource, tag, 'add');
            if (!resource.meta) {
                resource.meta = {tag: []};
            }

            if (codings)
                resource.meta.tag = codings;
        }

        return resource.meta?.tag;
    }

    public async update(resource: any, tagCoding: any) {
        if (!resource) return;
        if (!resource.meta) resource.meta = {};
        if (!resource.meta.tag) resource.meta.tag = [];

        // first check if the resource exists in the tags
        // let meta = await this.read(resource) || resource.meta.tag || []; // to do this reliable, load the original meta from fhir to be able to delete the correct one
        let existing = this.get(resource, tagCoding.system); // meta.find(o=>o.system === tag.system); // search a Tag which has the given system

        // don't update if the existing tag-value is the same value as the new tag value
        if (existing && existing.code === tagCoding.code) return;

        if (existing) {         // send exactly that tag in for deletion
            await this.delete(resource, existing);
        }

        // re-add the updated tag:
        await this.add(resource, tagCoding);

        // if (ConfigService.Debug) console.debug("Re-Adding Tag to Resource. Resulted in:", resource.meta);
    }

    public static System: string = 'http://nursit-institute.com/structureDefinitions/tags';

    public get system(): string {
        return FhirServiceMetaTag.System;
    }
}
